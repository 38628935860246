<template>
  <div class="page-content-main">
    <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" class="margin-top">
      <el-form-item label="企呼直通车二维码" prop="qihu_qr_code">
        <head-img-upload v-model="formData.qihu_qr_code"></head-img-upload>
      </el-form-item>
      <el-form-item label="留言板顶部图片" prop="messageboard_top_img">
        <head-img-upload v-model="formData.messageboard_top_img"></head-img-upload>
      </el-form-item>
      <el-form-item label="企呼直通车文字背景图(留言板)" prop="qihu_bg_img">
        <head-img-upload v-model="formData.qihu_bg_img"></head-img-upload>
      </el-form-item>
      <el-form-item label="企呼直通车顶部图片" prop="qihu_policy_top_img">
        <head-img-upload v-model="formData.qihu_policy_top_img"></head-img-upload>
      </el-form-item>
      <el-form-item label="企呼介绍(留言板)" prop="qihu_content_1">
        <el-input type="textarea" :rows="5" v-model="formData.qihu_content_1" style="width: 500px;"></el-input>
      </el-form-item>
      <el-form-item label="企呼介绍(惠企政策)" prop="qihu_content_2">
        <el-input type="textarea" :rows="5" v-model="formData.qihu_content_2" style="width: 500px;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')">确定</el-button>
        <el-button type="" @click="$router.back()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "qihuwoying",
  data() {
    return {
      formData: {
        qihu_qr_code:'',
        messageboard_top_img:'',
        qihu_bg_img:'',
        qihu_content_1:'',
        qihu_content_2:'',
        qihu_policy_top_img:''
      },
      rules: {
        qihu_qr_code:[{required:true,message:'内容必填'}],
        messageboard_top_img:[{required:true,message:'内容必填'}],
        qihu_bg_img:[{required:true,message:'内容必填'}],
        qihu_content_1:[{required:true,message:'内容必填'}],
        qihu_content_2:[{required:true,message:'内容必填'}],
        qihu_policy_top_img:[{required:true,message:'内容必填'}],
      },
    };
  },
  mounted(){
    this.getDetail();
  },
  methods: {
    submitForm(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.$http.request({
            url:'/SystemSetting/edit',
            datas:_this.formData,
            success:(res)=>{
              if(res === 'ok'){
                _this.$message.success('修改成功');
              }else{
                _this.$message.error('修改失败');
              }
              // _this.$message.info(res.msg);
              // _this.$router.replace({path: '/cate/list'})
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getDetail() {
      let _this = this;
      
      let _skeys = [];
      
      for(let k in this.formData){
        _skeys.push(k);
      }
      
      _this.$http.request({
        url:'/SystemSetting/getSystem',
        datas:{skey:_skeys},
        success:(res)=>{
          _this.formData = res;
        }
      });
    }
  }
}
</script>
